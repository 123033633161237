@import './KoneFont';
@import './variables';
@import './logoSpinner';
@import './kone-logo-spinner';
@import './flashPeople';
@import './waveArrow';
@import './buttonAnimation';
@import './transitions.scss';
@import '~bootstrap/scss/bootstrap.scss';

// When the variable overrides are not enough, we can define custom css here.


.bg-very-light {
    background-color: $very-light;
}
.text-very-light {
    color: $very-light;
}

.floor-row {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    border-bottom: 2px solid #4d4d4d;
}

.error-divider {
    border-top: 3px solid #4d4d4d
}

.error-view h5 {
    color: white;
}

.status-info, .survey-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.floor-row:last-child {
    border-bottom: none;
}

.floor-column {
    height: 50%;
}

.error-view {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.svg-helper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.animated-bg .lift-button.disabled {
    transition: none;
    background-color: #050505 !important;
}

.current-floor-helper:before {
    content: '';
    mask: url('people-single.svg');
    mask-size: cover;
    background-color: #f7f7f7;
    display: inline-block;
    color: white;
    position: absolute;
    height: 50%;
    width: 50%;
    top: 50%;
    left: -15%;
    transform: translateY(-50%) !important;
}

.info-current-floor-helper .people-logo-container {
    position: absolute;
    height: 75px;
    left: -50px;

    svg {
        position: absolute;
        height: 65px;
        left: 0px;
        margin-top: -32px;
        top: 50%;
    }
}

.error-icon {
    rect, circle, path {
        fill: #4d4d4d;
    }
}

.people-logo-container {
    fill: white;
    top: 100%;
    position: relative;
    transform: translateY(-100%);
    width: 4rem;
    margin-left: auto;
    margin-right: auto;
}

.status-arrow-big {
    display: inline-block;
    width: 100%;
    height: 100%;

    svg {
        position: relative;
        height: 65px;
        left: 0px;
        margin-top: -32px;
        top: 50%;
    }
}

.up-arrow, .down-arrow {
    position: fixed;
    mask: url('arrow-right-large-white.svg');
    mask-size: cover;
    background-color: #a1a1a1;
    right: 0px;
    height: 60px;
    width: 60px;
}

.up-arrow {
    top: 20px;
    transform: rotate(270deg);
}

.down-arrow {
    bottom: 20px;
    transform: rotate(90deg);
}

.textarea-container {
    width: 80%;
    margin: 0 auto;
}

.w-80-center {
    width: 80%;
    margin: 0 auto;
}

.survey-textarea {
    background-color: #050505;
    color: #d2d6dc;
    border: 2px solid #d2d6dc;
    border-bottom: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    height: 12rem;
    width: 100%;
    padding: 5px;
}

.survey-button {
    border: 2px solid #d2d6dc;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-top: -7px;
    height: 3rem;
    color: #d2d6dc;
    font-size: 2rem;
    line-height: 1rem;
    background-color: #0e70b8;
}

// Attempt to fix iOS floorpicker functionality
body, header {
    width: 100%;
    background-color: #050505;
}

.lift-button, .lift-button.disabled, .lift-button:hover {
    background-color: #050505;
    line-height: 0px;
    color: #f7f7f7 !important;
    opacity: 1;
    height: 60% !important;
    width: 60% !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    position: absolute;
    border: none;
    text-decoration: none;
    user-select: none;
    outline: none;
}

.lift-info-button {
    height: 75px !important;
    width: 75px !important;
    line-height: 0;
    border-radius: 10px !important;
    opacity: 1 !important;
    background-color: #050505 !important;
    color: #f7f7f7 !important;
}

.lift-button.current-floor .lift-info-button-destination, .lift-info-button-destination:disabled {
    border: 2px solid white !important;
}

/* For ios-like floorpicker */
.btn-square {
    color: $KONE_LIGHT_BLUE;
    font-size: 2.8rem;
    padding: 0;
    font-family: "KoneButtons";
}

.white-blur {
    /* TODO: This would require a lot of z-index mucking to actually work */
    /* Intended for the floorpicker numbers top & bottom edges */
    -webkit-box-shadow: inset 0px 0px 128px 84px rgba(255,255,255,0.85);
    -moz-box-shadow: inset 0px 0px 128px 84px rgba(255,255,255,0.85);
    box-shadow: inset 0px 0px 128px 84px rgba(255,255,255,0.85);
}

/* For old design */
.btn-circle {
    width: 55px;
    height: 55px;
    padding: 7px 10px;
    border-width: 3px;
    border-radius: 25px;
    text-align: center;
}

.info-circle {
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 1.5rem;

    padding: 0.14rem 0rem;
    border-width: 0px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
}

.info-big {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 5rem;

    padding: 0.30rem 0rem;
    font-size: 2.2rem;
}

@import './blinkBorder';
