svg.loading { /*svg sizes, layouts*/
    height: 128px; 
    width: 128px;
    display: inline-block;
    vertical-align: middle;
    fill: #0071B9;
}

.loading-logo {
    transform: translate(18px,35px);
}

.rect {
    fill:#fff;
    width:22px;
    height:22px;
}

.txt {
    fill: #fff;
    opacity: 0;
    transform:translate(0px,11px);
}

/*unite offset*/
.loading-k {transform: translate(0px,0);}
.loading-o {transform: translate(0px,0);}
.loading-n {transform: translate(0px,0);}
.loading-e {transform: translate(0px,0);}

/*rect animations sequences*/
.loading-k_rect {
    opacity: 0;
    animation: drop1 2s cubic-bezier(0,.64,.69,1) 0s infinite normal;
}
.loading-o_rect {
    opacity: 0;
    animation: rise1 2s cubic-bezier(0,.64,.69,1) 0.5s infinite normal;
}
.loading-n_rect {
    opacity: 0;
    animation: drop1 2s cubic-bezier(0,.64,.69,1) 1s infinite normal;
}
.loading-e_rect {
    opacity: 0;
    animation: rise1 2s cubic-bezier(0,.64,.69,1) 1.5s infinite normal;
}

@keyframes drop1 {
    0%   {opacity: 0;transform: translateY(-20px);}
    20%  {opacity: 0;transform: translateY(-20px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
    54%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
    68%  {opacity: 1;}
    100% {opacity: 0;}
}
@keyframes rise1 {
    0%   {opacity: 0;transform: translateY(20px);}
    20%  {opacity: 0;transform: translateY(20px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
    54%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
    68%  {opacity: 1;}
    100% {opacity: 0;}
}
