@keyframes wave {
    0% { 
        transform: translateX(0px);
    }
    100% { 
        transform: translateX(10px);
    }
 }

.status-arrow-small {
    animation-name: wave;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}