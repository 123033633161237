@keyframes slide-out {
    0% { 
        transform: translateY(0%);
    }
    100% { 
        transform: translateY(-100%);
    }
}

@keyframes status-slide-out {
    0% { 
        transform: translateY(0%);
    }
    100% { 
        transform: translateY(-200%);
    }
}

@keyframes slide-in {
    0% { 
        transform: translateY(100%);
        opacity: 0;
    }
    100% { 
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes lift-name-slide-in {
    0% { 
        transform: translateY(-25%);
        opacity: 0;
    }
    100% { 
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes lift-name-slide-side {
    0% { 
        transform: translateX(-48%);
    }
    100% { 
        transform: translateX(0%);
    }
}

@keyframes fade-out {
   0% {
      opacity: 1;
   }

   100% {
      opacity: 0;
   }
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.slide-out {
    animation-name: slide-out;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: alternate;
}

.status-slide-out {
    animation-name: status-slide-out;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: alternate;
}

.slide-in {
    animation-name: slide-in;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: alternate;
}

.lift-name-slide-side {
    animation-name: lift-name-slide-side;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: alternate;    
}

.slide-in-fast {
    animation-name: lift-name-slide-in;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: alternate;    
}
