@keyframes blink {
    0% { 
        border-color: rgba(255, 255, 255, 0.1);
    }
    100% { 
        border-color: rgba(255, 255, 255, 1);
    }
 }

 @keyframes blink-arrow {
    0% { 
        opacity: 0.1;
    }
    100% { 
        opacity: 1;
    }
 }

.floor-row .destination-floor, .lift-info-button-animated {
    border: 2px solid;
    animation-name: blink;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-color: #050505 !important;
    box-shadow: none !important;
}

.status-arrow-big-animated {
    animation-name: blink-arrow;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate; 
    display: inline-block;
    width: 100%;
    height: 100%;

    svg {
        position: relative;
        height: 65px;
        left: 0px;
        margin-top: -32px;
        top: 50%;
    }
}