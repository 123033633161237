.logo-spinner {
    svg { /*svg sizes, layouts*/
        height: 96px; 
        width: 96px;
        display: inline-block;
        vertical-align: middle;
        fill:red; 
    }
    
    #logo {
        transform: translate(18px,35px);
    }
    .rect {
        fill:#fff; width:22px;height:48px;
    }
    .txt {
        fill: #000; opacity: 1; transform:translate(0px,13px);
    }
    
    /*unit offset*/
    #k {transform: translate(0px,0px);}
    #o {transform: translate(0px,0px);}
    #n {transform: translate(0px,0px);}
    #e {transform: translate(0px,0px);}
    
    /*rect animations sequences*/
    #k {
      opacity: 0;
      animation: drop1 4s cubic-bezier(0,.64,.69,1) 0s infinite normal;}
    #o {
      opacity: 0;
      animation: rise1 4s cubic-bezier(0,.64,.69,1) 0.45s infinite normal;}
    #n {
      opacity: 0;
      animation: drop1 4s cubic-bezier(0,.64,.69,1) 0.9s infinite normal;}
    #e {
      opacity: 0;
      animation: rise1 4s cubic-bezier(0,.64,.69,1) 1.2s infinite normal;}
}

@keyframes drop1 {
  0%   {opacity: 0;transform: translateY(-36px);}
  10%   {opacity: 0;transform: translateY(-36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
  30%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
  55% {opacity: 1;}
  80% {opacity: 1;transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
  100% {opacity: 0;transform: translateY(36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
}
@keyframes rise1 {
  0%   {opacity: 0;transform: translateY(36px);}
  10%  {opacity: 0;transform: translateY(36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
  30%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
  55% {opacity: 1;}
  80% {opacity: 1;transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
  100% {opacity: 0;transform: translateY(-36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
}