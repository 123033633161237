@keyframes show-bg {
    0% { 
        background-color: #b3b3b300;
        text-shadow: 1px 3px 5px grey;
    }
    10% {
        background-color: #b3b3b3;
        text-shadow: 1px 3px 5px grey;
    }
    50% {
        background-color: #b3b3b3;
        text-shadow: 1px 3px 5px grey;
    }
    100% { 
        background-color: #b3b3b300;
        text-shadow: 1px 3px 5px #b3b3b300;
    }
}

.animated-bg .lift-button {
    animation-name: show-bg;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: alternate;
}