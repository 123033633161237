@keyframes blink-logo {
    0% {
        fill: rgba(255, 255, 255, 0.5);
    }
    100% {
        fill: rgba(255, 255, 255, 1);
    }
}

@keyframes blink-text {
    0% {
        color: rgba(255, 255, 255, 0.5);
    }
    100% {
        color: rgba(255, 255, 255, 1);
    }
}

.people-logo-container-animated {
    animation-name: blink-logo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    height: 4rem;
    width: 4rem;
}

.lift-name-text {
    animation-name: blink-text;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}
