@font-face {
  font-family: 'Kone';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
       url('./fonts/KONE_Information.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'KoneButtons';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
       url('./fonts/KONE_Buttons_v12.otf') format('truetype'), /* Safari, Android, iOS */
}
