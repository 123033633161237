// Override bootstrap variables here. Full list can be found here:
// https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss

// Misc

$border-radius: 0.75rem;


// Colors
$KONE_BLUE: #1A4EA6;
$KONE_LIGHT_BLUE: #0e70b8;

$dark: #8d9194;
$light: #babdbf;
$very-light: #e6e6e6;

$primary: white;
$secondary: white;
$warning: #F6A800;

$info: #edeff0;

$body-bg: $primary;
$body-color: $light;

$link-color: #57a9d6;


// Fonts
$font-family-base: "Kone";
